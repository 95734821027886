import React from 'react';

import styled from '@emotion/styled';
import {css} from '@emotion/core';
import authIntroImage from '../images/auth-intro.svg';
import {Link} from 'gatsby';

const Container = styled.div`
	margin: 8px auto;
`;

const imageTop = css`
	width: 90%;
	margin-top: 10vh;
	margin-bottom: 2vh;
	margin-left: auto;
	margin-right: auto;
	display: block;
`;

const head = css`
	/* max-width: 281px; */
	height: 38px;
	font-family: Nunito;
	font-size: 7vw;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.36;
	-webkit-letter-spacing: normal;
	-moz-letter-spacing: normal;
	-ms-letter-spacing: normal;
	letter-spacing: normal;
	text-align: center;
	color: #000000;
	margin-top: 0vh;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 0px;
`;

const stunner = css`
	max-width: 277px;
	height: 59px;
	font-family: Nunito;
	font-size: 4vw;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	-webkit-letter-spacing: normal;
	-moz-letter-spacing: normal;
	-ms-letter-spacing: normal;
	letter-spacing: normal;
	text-align: center;
	color: #a2a1a1;
	margin-top: 7px;
	margin-left: auto;
	margin-right: auto;
`;

const bottomLine = css`
	height: 20px;
	font-family: Nunito;
	font-size: 4vw;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: center;
	color: #80e8fe;
	margin-top: 6vh;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 2vh;
`;

const signup = css`
	width: 100vw;
	max-width: 250px;
	height: 6vh;
	border-radius: 30px;

	background-color: #80e8fe;
	color: white;
	margin: 0 auto;
	display: block;
	outline: none;
`;

const signin = css`
	width: 100vw;
	max-width: 250px;
	height: 6vh;
	border-radius: 30px;
	border: solid 2px #80e8fe;
	color: #80e8fe;
	background-color: #ffffff;
	margin: 2vh auto;
	display: block;
	outline: none;
`;

const aLink = css`
	text-decoration: none;
`;

export default () => (
	<Container>
		<img css={imageTop} src={authIntroImage} alt="autosave svg" />
		<p css={head}>Future of bank is here</p>
		<p css={stunner}>
			Just give your details and select the bank to fix deposit and start saving
		</p>
		<p css={bottomLine}>To everything begin just one step a head.....</p>
		<Link css={aLink} to="/signup">
			<button css={signup}>Sign up</button>
		</Link>
		<Link css={aLink} to="/signin">
			<button css={signin}>Sign in</button>
		</Link>

		<Link to="/">Home</Link>
	</Container>
);
